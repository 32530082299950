//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : _company-info.scss
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (12 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240712     Jecyline     create _company-info.scss
//
//***************************************************************************/
.company-info {
  .btn-primary.company-btn {
    background: linear-gradient(to bottom, #A901F7, #DD4B39);
    border: 0;
    display: block;
    font-size: $font-size-16;
    @include rounded(15px);
    @include margin-padding(null,  8px 26px);
  }

  .account-icon-button {
    // width: 100%;
    background: #1877F2;
    color: white;
    border: 1px solid #CBC9C9;
    font-size: $font-size-18;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;

    @include rounded(4px);
    @include margin-padding(null,  10px 26px);

    &:hover {
        color: #1877F2;
        background: transparent;
    }

    .account-icon {
      font-size: 25px;
      height: 100%;
      margin: auto;
      width: 40px;
    }

    .account-img-icon {
        height: 25px;
        width: 25px;
        margin-right: 10px;
        object-fit: cover;
    }
  }
}