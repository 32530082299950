.account-page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;

    .cover-wrapper {
        .cover-div{
            img{
                border-radius: 15px;
            }
        }
        @include respond-below(custom575) {
            display: none;
        }
    }
    .reset-wrapper
    {
        width: 100%;
        @include respond-below(custom575) {
            width: 100%;
        }

        .main-wrapper {
            // width: 95%;
            display: block;
            margin: 0 auto;
            // display: -webkit-box;
            // display: -ms-flexbox;
            // display: flex;
            // justify-content: center;
            // -webkit-justify-content: center;
            // -ms-flex-pack: center;
            // -ms-flex-wrap: wrap;
            // flex-wrap: wrap;
            .account-content {
                @include margin-padding(null, 20px 0);
                .account-logo {
                    margin-bottom: 10%;
                    text-align: center;
                    img {
                        width: 30%;
                        @include respond-below(custom575) {
                            width: 250px;
                        }
                    }
                }
                .account-box {
                    // background-color: transparent;
                    background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(54, 53, 63, 0.2));
                    margin: 0 auto;
                    overflow: hidden;
                    width: 480px;
                    padding: 30px;
                    @include rounded(25px);
                    @include respond-below(custom991) {
                        width: 100%;
                        @include margin-padding(0 0 20px, null);
                    }
                    .input-block {
                        margin-bottom: 3%;
                    }
                    label {
                        color: $white;
                        font-size: $font-size-14;
                        font-weight: normal;
                    }
                    .form-control {
                        // background-color: $light-200;
                        // border: 1px solid $light-900;
                        background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(54, 53, 63, 0.2));
                        border: 1px solid $color-gray;
                        height: 40px;
                        padding-left: 45px;
                        @include rounded(4px);
                    }
                    .form-control::placeholder {
                        color:$color-font-gray;
                    }
                    #toggle-password {
                        cursor: pointer;
                        margin-right: 1px;
                        @include position(absolute,15px,10px,null,null);
                    }
                    .account-btn {
                        // background: $btn-gradient;
                        background: linear-gradient(to bottom, #A901F7, #DD4B39);
                        border: 0;
                        display: block;
                        font-size: $font-size-14;
                        width: 100%;
                        @include rounded(15px);
                        @include margin-padding(null,  10px 26px);
                    }


                    .account-icon-button {
                        width: 100%;
                        background: transparent;
                        border: 1px solid #CBC9C9;
                        font-size: $font-size-22;
                        cursor: pointer;
                        display: inline-flex;
                        align-items: center;
                        justify-content: space-around;

                        @include rounded(4px);
                        @include margin-padding(null,  10px 26px);
                        
                        &:hover {
                            color: $indigo;
                        }

                        .account-icon {
                            font-size: 25px;
                            height: 100%;
                            margin: auto;
                            width: 40px;
                        }

                        .account-img-icon {
                            height: 25px;
                            width: 25px;
                            object-fit: cover;
                        }
                        }
                    .account-wrapper {
                        // @include margin-padding(null, 30px);
                        .account-footer {
                            text-align: center;
                        }
                        .account-title {
                            font-size: $font-size-16;
                            font-weight: $font-weight-medium;
                            margin-bottom: 5px;
                            text-align: center;
                        }
                        .account-subtitle {
                            color: $secondary-600;
                            font-size: $font-size-14;
                            text-align: center;
                            @include margin-padding(0 0 30px,null);
                        }
                        .account-box {
                            .input-block {
                                @include margin-padding(0 0 25px,null);
                                label {
                                    color: $secondary;
                                    font-size: $font-size-14;
                                    font-weight: normal;
                                    margin-bottom: 5px;
                                }
                                .form-control {
                                    background-color: $light-200;
                                    border: 1px solid $light-900;
                                    height: 46px;
                                    @include rounded(4px);
                                }
                            }
                        }
                        @include respond-below(custom575) {
                            padding: 30px 0 0 0;
                        }
                    }
                    .otp-wrap {
                        margin-bottom: 30px;
                        text-align: center;
                        .otp-input {
                            background-color: $white;
                            border: 1px solid $light-900;
                            display: inline-block;
                            font-size: $font-size-24;
                            font-weight: $font-weight-medium;
                            height: 70px;
                            line-height: 29px;
                            margin-right: 15px;
                            text-align: center;
                            width: 70px;
                            @include rounded(4px);
                            @include respond-below(custom575) {
                                height: 50px;
                                width: 52px;
                                @include margin-padding(10px, null);
                            }
                        }
                    }
                    .account-box-title {
                        font-size: $font-size-20;
                        font-weight: $font-weight-medium;
                        text-align: center;
                    }
                    .account-box-subtitle {
                        font-size: $font-size-12;
                        font-weight: $font-weight-lighter;
                        margin-bottom: 15px;
                        text-align: center;
                    }
                }
            }
        }
    }
}
.front-div{
    padding-right: 0px;
    padding-left: 0px;
}