//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : _popup.scss
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240701     Glynnis      Add _popup.scss
//* 20240724     Jecyline     Add modal-backdrop and modal-backdrop.show
//* 20240906     Glynnis      Revamp UI
//
//***************************************************************************/
.custom-modal {
	.modal-content {
		border: 0;
		@include rounded(10px);
		background: $modal-content-bg;
		// .btn {
		// 	background-image: linear-gradient(to right, #F965FD 0%, #7151DF 32%, #271956 100%);
		// }
		.modal-header {
			border: 0;
			justify-content: space-between;
			-webkit-justify-content: space-between;
			-ms-flex-pack: space-between;
			background: $color-darkgray;
			@include margin-padding(null, 30px 30px 0);
			.modal-title {
				font-size: $font-size-22;
				color: $modal-title;
			}
			.btn-close {
				background-color: $secondary-400;
				border: 0;
				color: $white;
				font-size: $font-size-13;
				height: 20px;
				line-height: 20px;
				opacity: 1;
				width: 20px;
				z-index: 99;
				@include margin-padding(0, 0);
				@include rounded(50%);
			}
		}
		.modal-body {
			@include margin-padding(null, 30px);
			color: $modal-body;
			background: $color-darkgray;
			label {
				color: $modal-body;
			}
			.attach-files {
				ul{
					padding: 0;
					margin: 0;
					list-style: none;
					li{
						display: inline-block;
						margin-right: 10px;
						position: relative;
						img {
							width: 50px;
						}
						.file-remove {
							color: $danger;
							@include position(absolute,-7px,-6px,null,null);
						}
					}
				}
			}
			.status-toggle {
				float: left;
			}
			.input-block {
				margin-bottom: 1rem;
				float: left;
				width: 100%;
				&.col-sm-4 {
					width: 33.3%;
				}
				&.col-sm-6 {
					width: 50%;
				}
			}
			.add-group-btn{
				.btn-primary {
					margin-top: 15px;
				}
			}
			.chat-user-list {
				padding: 0;
				margin: 0;
				list-style: none;
				.chat-block {
					border-bottom: 1px solid $secondary-100;
					border-radius: inherit;
					background-color: $white;
					@include margin-padding(null, 10px);
					.user-name {
						color: $secondary-900;
					}
					.designation {
						color: $secondary-500;
						font-size: $font-size-12;
					}
					.online-date {
						color: $secondary-500;
						font-size: $font-size-12;
					}
					&:hover {
						background-color: $body-bg;
					}
				}
			}
		}
	}
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1040;
  display: none;
}

.modal-backdrop.show {
  display: block;
}

.submit-section {
    text-align: center;
	float: left;
    width: 100%;
	color: $modal-body;
	background: $color-darkgray;
	padding-bottom: 20px;
	@include respond-below(custom575) {
		// margin-top: 20px;
	}
	.submit-btn {
		font-size: $font-size-18;
		font-weight: $font-weight-semibold;
		min-width: 200px;
		@include rounded(50px);
		@include margin-padding(null, 10px 20px);
		@include respond-below(custom575) {
			@include margin-padding(10px 0 0, null);
			font-size: $font-size-14;
		}
	}
}
.task-follower-list{
	margin-top: 15px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	span {
		position: relative;
		i {
			cursor: pointer;
			color: $danger;
			@include position(absolute,-5px,5px,null,null);
		}
	}
}
.task-board-color{
	.board-color-list {
		height: 45px;
		-ms-flex-wrap: wrap;
  		flex-wrap: wrap;
		display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
		@include respond-below(custom991) {
			margin-bottom: 40px;
		}
		.board-control {
			position: relative;
			min-height: 1.5rem;
			cursor: pointer;
			width: 36px;
			height: 36px;
			margin-bottom: 10px;
			@include margin-padding(0 1rem 10px 0, null);
			display: -webkit-inline-box;
			display: -ms-inline-flexbox;
			display: inline-flex;
			.board-indicator {
				width: 36px;
				height: 36px;
			}
		}
	}
	.board-control-input {
		position: absolute;
		z-index: -1;
		opacity: 0;
		&:checked{
			~ .board-indicator{
				&:before{
				    content: "\f00c";
					display: block;
					width: 16px;
					height: 16px;
					color: $white;
					border: none;
					font-family: FontAwesome;
					text-align: center;
					line-height: 16px;
					@include rounded(2px);
					@include position(relative,10px,null,null,10px);
				}
			}
		}
	}
	.board-primary{
		.board-indicator {
			background: #ff9b44;
		}
	}
	.board-success {
		.board-indicator {
			background: #4caf50;
		}
	}
	.board-info {
		.board-indicator {
			background: #42a5f5;
		}
	}
	.board-purple {
		.board-indicator {
			background: #7460ee;
		}
	}
	.board-warning {
		.board-indicator {
			background: #ffb300;
		}
	}
	.board-danger {
		.board-indicator {
			background: #ef5350;
		}
	}
}
.leave-duallist {
    background-color: $light-200;
    border: 1px solid $light-900;
    display: block;
	@include margin-padding(null, 15px);
    select.form-control[multiple] {
		height: 200px;
		@include margin-padding(null, 7px);
		option {
			@include rounded(3px);
			@include margin-padding(null, 7px 12px);
		}
	}
}
.form-header {
    text-align: center;
    margin-bottom: 30px;
}
.continue-btn,
.cancel-btn {
    background-color: $white;
    border: 1px solid $primary;
    color: $primary !important;
    display: block;
    font-size: $font-size-18;
    font-weight: $font-weight-semibold;
    text-align: center;
	@include rounded(50px);
	@include margin-padding(null, 10px 20px);
	&:hover{
		color: $white !important;
	}
}
