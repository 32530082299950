//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : _chat.scss
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240701     Glynnis      Add _chat.scss
//* 20240724     Jecyline     Add chat-img
//* 20240726     Jecyline     Add chat-center
//* 20240730     Jecyline     Amend chat-bubble by replace margin-bottom to padding-bottom
//* 20240805     Jecyline     Align left chat bubble time to right, add chat-feedbak css
//* 20240808     Jecyline     Amend reaction icon ui and add animation to it
//* 20240813     Jecyline     Adjust chat selection ui and add animation
//* 20240906     Jecyline     Adjust chat selection ui
//* 20240903     Glynnis      Revamp UI
//* 20240910     Glynnis      Amended suggestion UI
//* 20240930     Jecyline     Add chat selection facebook post design (ad-post-container)
//
//***************************************************************************/
.chat-contents {
    display: table-row;
    height: 100%;
    .chat-content-wrap {
        height: 100%;
        position: relative;
        width: 100%;
        .chats {
            @include margin-padding(null, 30px 15px);
            .chat-body {
                @include margin-padding(null,0 10px 10px 0px);
            }
            .chat-bubble {
                display: block;
                width: 100%;
                float: left;
                // margin-bottom: 10px;
                padding-bottom: 10px;
                &:first-child{
                    .chat-content {
                        border-radius: 10px;
                    }
                }
                &:last-child{
                    .chat-content {
                        border-radius: 10px;
                    }
                }
                .chat-content{
                    .chat-img {
                        width: 100%;
                        max-width: 300px;
                        height: auto;
                        object-fit: contain;
                    }
                    .chat-text{
                        padding-top:5px;
                        padding-bottom:5px;
                        padding-left: 5px;
                    }
                    .chat-time {
                        // color: rgba(0, 0, 0, 0.5);
                        color: $offwhite;
                        display: block;
                        font-size: $font-size-10;
                    }
                    .chat-head {
                        display: block;
                        font-size: $font-size-10;
                    }
                    .attach-list {
                        color: $secondary-400;
                        line-height: 24px;
                        list-style: none;
                        @include margin-padding(0, 5px 0 0);
                        .task-attach-img {
                            @include margin-padding(0 0 20px, null);
                        }
                    }
                    .chat-img-group{
                        p{
                            @include margin-padding(0 0 20px, null);
                        }
                    }
                    p {
                        @include margin-padding(0, null);
                        word-wrap: break-word;
                    }
                }

                &:hover{
                    .chat-action-btns {
                        display: block;
                    }
                }
                .chat-action-btns {
                    display: none;
                    ul {
                        list-style: none;
                        @include margin-padding(15px 15px 0 15px, 0);
                        li {
                            display: inline-block;
                            margin-left: 5px;
                            font-size: $font-size-18;
                            a {
                                color: $secondary-600;
                            }
                        }
                    }
                }
                .chat-img-attach {
                    position: relative;
                    float: right;
                    @include rounded(4px);
                    @include margin-padding(0 0 12px 12px, null);
                    .chat-placeholder {
                        background: rgba(69, 81, 97, 0.6);
                        @include rounded(4px);
                        color: $white;
                        height: 100%;
                        width: 100%;
                        @include position(absolute,0,null,null,0);
                        -ms-flex-direction: column;
                        flex-direction: column;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        justify-content: flex-end;
                        -webkit-justify-content: flex-end;
                        -ms-flex-pack: flex-end;
                        @include margin-padding(null, 13px);
                        .chat-img-name {
                            font-size: $font-size-12;
                            font-weight: $font-weight-bold;
                            line-height: 16px;
                        }
                        .chat-file-desc {
                            font-size: $font-size-11;
                        }
                    }
                }
            }
            .chat-right {
                .chat-body {
                    @include margin-padding(null, 0 10px 0 48px );
                    .chat-content {
                        // background-color: $light-400;
                        // border: 1px solid $light-900;
                        clear: both;
                        color: $secondary-300;
                        display: block;
                        float: right;
                        max-width: 80%;
                        width:auto;
                        position: relative;
                        padding-top:10px;
                        padding-bottom:10px;
                        padding-left:15px;
                        padding-right:15px;
                        @include rounded(10px);
                        .chat-time {
                            text-align: right;
                        }
                        &.img-content {
                            background-color: transparent;
                            border: 0;
                            color: $secondary-600;
                            padding: 0;
                            text-align: right;
                        }
                       
                    }
                    .chat-time {
                        text-align: right;
                    }
                    .chat-action-btns {
                        float: right;
                    }
                }
            }
            .chat-center {
                .chat-body {
                    .chat-content {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 100%;
                      position: relative;
                      span{
                        @include margin-padding(8px 0,5px 15px);
                        background-color: $white;
                        color: $secondary-500;
                        font-size: $font-size-12;
                        border-radius: 20px;
                      }
                    }
                }
            }
            .chat-left{
                width: 100%;
                float: left;
                .chat-avatar {
                    float: left;
                    .avatar {
                        line-height: 30px;
                        height: 30px;
                        width: 30px;
                    }
                }
                .chat-body {
                    @include margin-padding(0, 0 20px 0 0);
                    display: block;
                    overflow: hidden;
                }
                .chat-content {
                    // background-image: $bg-chat-assistant;
                    // border: 1px solid $secondary-100;
                    color: $offwhite;
                    float: left;
                    position: relative;
                    max-width: 80%;
                    width:auto;
                    padding-top:10px;
                    padding-bottom:10px;
                    padding-left:15px;
                    padding-right:15px;
                    @include rounded(10px);
                    @include respond-below(custom575) {
                        max-width:80%;
                    }
                    .chat-time {
                      text-align: right;
                      color: $offwhite;
                    }
                }
                .chat-bubble{
                    &:first-child{
                        .chat-content {
                            border-radius: 10px;
                        }
                    }
                    &:last-child{
                        .chat-content {
                            border-radius: 10px;
                        }
                    }
                }
                .chat-img-attach {
                    float: left;
                    @include margin-padding(0 12px 12px 0,null);
                }
                //fb ads container :start
                .chat-body.facebook-ads {
                    @include margin-padding(0, 0 20px 0 0);
                    display: block;
                    overflow: hidden;
                    .chat-content {
                        max-width: 100%;
                        padding-left: 15px;
                        padding-right: 15px;
                        .ad-post-container {
                            max-width: 400px;
                        }
                        @include respond-below(custom575) {
                            .ad-post-container {
                                max-width: 360px;
                            }
                        }
                        
                        .profile-picture {
                            overflow: hidden;
                        }
                        
                        .ad-image img {
                            height: 250px;
                        }

                        .line2 {
                            max-height: 200px;
                        }
                        .line4 {
                            max-height: 300px;
                        }
                    }
                }
                //fb ads container :end
                //final result container :start
                .chat-body.final-result {
                    @include margin-padding(0, 0 20px 0 0);
                    display: block;
                    overflow: hidden;
                    .chat-content {
                        max-width: 100%;
                        padding-left: 15px;
                        padding-right: 15px;
                        .fr-container {
                            background-image: transparent;//linear-gradient(to right, #7585F1, #DB25DF, #7151DF);
                            border-radius: 5px;
                            padding: 8px;
                            margin: 8px;

                            .fr-box {
                                border-radius: 5px;
                                max-width: 400px;
                                padding: 25px;
                                background-color: #fff;
                                margin: 3px;
                                .fr-img{
                                    max-width: 100%;
                                    height: auto;
                                    margin-top: 1rem;
                                }
                                .fr-body{
                                    color: #4E527C !important;
                                    // margin: 10px 0;
                                    //   max-height: 150px;
                                    text-align: left;
                                    //   display: -webkit-box;
                                    //   -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                    overflow: scroll;
                                    white-space: normal;
                                    /* text-overflow: ellipsis; */
                                }
                                .line2 {
                                    max-height: 200px;
                                }
                                .line4 {
                                    max-height: 300px;
                                }
                                p, span {
                                    color: #333;
                                }
                            }
                        }
                    }
                }
                //final result container :end
                //brand boost container :start
                .chat-body.brandboost {
                    @include margin-padding(0, 0 20px 0 0);
                    display: block;
                    overflow: hidden;
                    .chat-content {
                        max-width: 100%;
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                    .brandboost-container {
                        padding: 8px;
                        margin: 8px;
                    }
                }
                //brand boost container :end
            }
            .chat-feedback{
              width: 100%;
              float: left;
              .chat-body {
                  @include margin-padding(0, 0 20px 0 0);
                  display: inline-block;
                  padding-bottom: 10px;
                  .reaction-icons {
                    display: flex;
                    // gap: 12px;
                    align-items: center;
                    .reaction-icon {
                      height: 30px;
                      width: 30px;
                      border-radius: 10px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-size: 1rem;

                      @keyframes fadeIn {
                        from {
                            opacity: 0;
                        }
                        to {
                            opacity: 1;
                        }
                      }
  
                      .thumbs {
                        color: $primary-100;
                      }

                      .favourite {
                        color: $primary-100;
                      }

                      &:hover {
                        background-color: $primary-700;
                        transition: background-color 0.5s ease-in-out;

                        &.active {
                          background-color: unset;
                        }
                      }

                      &.active {
                        .thumbs {
                          color: $primary-900;
                          animation: fadeIn 0.5s ease-in-out;
                        }

                        .favourite {
                          color: $yellow;
                          animation: fadeIn 0.5s ease-in-out;
                        }
                        
                      }
                    }
                  }
              }
            }
            
        }
        
    }
}
.chat-sidebar{
    .chat-line {
        .chat-date{
            background-color: $white;
            top: 10px;
        }
    }
}
.chat-line {
    border-bottom: 1px solid $secondary-100;
    position: relative;
    text-align: center;
    width: 100%;
    @include margin-padding(7px 0 20px, null);
    .chat-date {
        background-color: $body-bg;
        color: $secondary-300;
        font-size: $font-size-12;
        @include margin-padding(null, 0 11px);
        @include position(relative,7px,null,null,-15px);
    }
}
.chat-main-row {
    // overflow: auto;
    padding-bottom: inherit;
    padding-top: inherit;
    position: fixed;
    top: 0;
    bottom: 0;
    .chat-main-wrapper {
        display: table;
        height: 100%;
        table-layout: fixed;
        width: 100%;
        .task-view {
            .chat-starter {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .message-view {
            display: table-cell;
            height: 100%;
            float: none;
            position: static;
            vertical-align: top;
            width: 75%;
            @include margin-padding(null, 0);
            @include respond-below(custom991) {
                width: 100%;
            }
            
        }
        .chat-sidebar {
            border: 1px solid $color-gray;
            width: 100%;
           
        }
    }
}
.chat-sidebar {
    .chat-contents {
        border-left: 1px solid $color-gray;
        .chats {
            @include margin-padding(null, 15px 15px 30px);
            .chat-left {
                @include margin-padding(0 0 20px, null);
                .chat-avatar {
                    float: left;
                    .avatar {
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                    }
                }
                .chat-body {
                    @include margin-padding(0 0 0 50px, 0);
                    .chat-bubble {
                        display: block;
                        width: 100%;
                        float: left;
                        @include margin-padding(0 0 10px, null);
                        .chat-content {
                            border: 0;
                            padding: 0;
                            background-color: transparent;
                            max-width: 100%;
                            @include rounded(0 !important);
                            .task-chat-user {
                                color: $secondary-900;
                                font-size: $font-size-13;
                            }
                            .chat-time {
                                color: $secondary-600;
                                display: inline-block;
                                font-size: $font-size-10;
                            }
                            .chat-head{
                                color: $secondary-600;
                                display: inline-block;
                                font-size: $font-size-10;
                            }
                            .call-details {
                                @include margin-padding(10px 0 0px, null);
                                display: -webkit-box;
                                display: -ms-flexbox;
                                display: flex;
                                .call-info {
                                    margin-left: 10px;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.video-window {
    .fixed-header {
        padding: 0 !important;
        border: 0;
        .nav {
            li{
                a{
                    @include margin-padding(null, 18px 15px);
                }
            }
        }
    }
}
.chat-profile-img {
    padding: 30px;
    position: relative;
    text-align: center;
    .edit-profile-img {
        height: 120px;
        margin: 0 auto;
        position: relative;
        width: 120px;
        cursor: pointer;
        img {
            height: auto;
            margin: 0;
            width: 120px;
            @include rounded(50%);
        }
        .change-img {
            background-color: rgba(0, 0, 0, 0.3);
            color: $white;
            display: none;
            height: 100%;
            line-height: 120px;
            width: 100%;
            @include position(absolute,0,null,null,0);
            @include rounded(50%);
        }
        &:hover{
            .change-img {
                display: block !important;
            }
        }
    }
    .edit-btn {
        height: 36px;
        width: 36px;
        @include position(absolute,15px,15px,null,null);
        @include rounded(40px);  
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        i {
        font-size: 13px;
        }         
    }
}
.files-list {
    list-style: none;
    padding-left: 0;
   > li {
        border-bottom: 1px solid $secondary-100;
        @include margin-padding(2px 0 0, 10px);
        @include rounded(inherit);
        .files-cont {
            position: relative;
            .file-type {
                height: 48px;
                position: absolute;
                width: 48px;
                .files-icon {
                    background-color: $light-300;
                    border: 1px solid $light;
                    display: -webkit-inline-box;
                    display: -ms-inline-flexbox;
                    display: inline-flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    justify-content: center;
                    -webkit-justify-content: center;
                    -ms-flex-pack: center;
                    height: 38px;
                    line-height: 38px;
                    text-align: center;
                    width: 38px;
                    @include rounded(4px);
                    i {
                        color: $secondary-600;
                        font-size: $font-size-20;
                    }
                }
            }
            .files-info {
                @include margin-padding(null, 0 30px 0 50px);
                .text-ellipsis {
                    display: block;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .file-author {
                    a {
                        color: $primary;
                        font-size: $font-size-12;
                        text-decoration: underline;
                    }
                    .file-date {
                        color: $secondary-600;
                        font-size: $font-size-12;
                    }
                }
            }
            .files-action {
                display: none;
                height: 30px;
                list-style: none;
                padding-left: 0;
                text-align: right;
                width: 30px;
                @include position(absolute,0,0,null,null);
                a {
                    color: $secondary-600;
                }
            }
        }
        &:hover{
            .files-action {
                display: block;
            }
        }
    }
}
.search-box {
    margin-top: 5px;
    @include respond-below(custom991) {
        display: none;
    }
    .input-group {
        background-color: $body-bg;
        border: 1px solid $light-900;
        @include rounded(50px);
        .form-control {
            background: transparent;
            border: none;
        }
        .btn {
            background-color: transparent;
            border: 0;
        }
    }
}
.chat-upload-file {
    margin: 10px 0;
}
.upload-drop-zone {
    background-color: $white;
    border: 2px dashed $light-900;
    color: $secondary-200;
    height: 200px;
    line-height: 200px;
    margin-bottom: 20px;
    text-align: center;
    .upload-text {
        font-size: 24px;
        margin-left: 10px;
    }
}
.upload-list {
    padding: 0;
    margin: 0;
    list-style: none;
    .file-size {
        color: $secondary-600;
    }
    .file-list{
        background-color: $white;
        border-top: 1px solid $light-900;
        padding: 10px 0;
        &:first-child {
            border-top: none;
        }
    }
    .upload-wrap {
        position: relative;
        @include margin-padding(0 0 5px, 0 20px 0 0);
    }
    .file-name {
        padding-right: 15px;
        overflow: hidden;
        max-width: 100%;
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: top;
        white-space: nowrap;
        color: $secondary-600;
        margin: 0 5px 0 0;
        vertical-align: middle;
    }
    .file-close {
        border: none;
        background: none;
        color: $secondary-200;
        @include position(absolute,2px,0,null,null);
    }
    .progress {
        margin-bottom: 5px;
    }
    .upload-process {
        font-size: $font-size-10;
        color: $secondary-600;
    }
}
@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}

.chat-selections.slide-up {
  display: block;
  animation: slideUp 0.3s ease-in-out forwards;
}

.chat-selections.slide-down {
  display: block;
  animation: slideDown 0.3s ease-in-out forwards;
}

.chat-selections.hidden {
  display: none;
}

.chat-selections{
    width: 100%;
    opacity: 0;
    display: none;

    .selection-wrapper{

        border-radius: 10px;
        margin: 10px;
        padding: 15px;
        .selection-title {
            font-size: $font-size-16;
            font-weight: $font-weight-medium;
        }
        .selection-content-wrapper {
            overflow: auto;
            white-space: nowrap;
            .selection-content-dropdown{
                display: flex; /* Align items in a row */
                align-items: center; /* Center items vertically */

                .input-block {
                    margin-right: 10px; /* Space between dropdown and button */
                  }
                  
                  .dropdown {
                    width: 300px; /* Make the dropdown responsive */
                  }
                  
                  .dropdown-button {
                    padding: 5px 10px; /* Adjust button padding */
                    cursor: pointer; /* Change cursor on hover */
                  }
                  
            }
            .selection-content-box {
                display: inline-block;
                // width: 350px;
                min-width: 100px;
                @include respond-below(custom767) {
                    // width: 250px;
                    min-width: 80px;
                }
                @include respond-below(custom575) {
                    // width: 150px;
                    min-width: 60px;
                }
                min-height: 10px;
                // background: $btn-gradient-call-to-action;
                background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(54, 53, 63, 0.2));
                border-radius: 15px;
                border-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(54, 53, 63, 0.2)) 1; /* Border gradient */
                color: $white;
                text-align: center;
                padding: 0px;
                margin: 5px;
                overflow: overflow-x;
                white-space: nowrap;
                .selection-content-img {
                  max-width: 100%;
                  max-height: 100px;
                  overflow: hidden;
                  cursor: pointer;
                  img {
                    width: 100%;
                    height: auto;
                    max-height: 100px;
                    object-fit: contain;
                  }
                  @include respond-below(custom767) {
                    max-height: 80px;
                    img {
                      max-height: 80px;
                    }
                  }
                  @include respond-below(custom575) {
                    max-height: 60px;
                    img {
                      max-height: 60px;
                    }
                  }
                }
                .selection-content-text {
                  max-width: 350px;
                  max-height: 200px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: normal;
                  .title {
                    font-size: $font-size-14;
                    font-weight: $font-weight-light;
                    margin: 0 5px 0 5px;
                    padding: 10px;
                  }
                  .desc {
                    font-size: $font-size-12;
                    margin: 0 5px 0 5px;
                  }
                  @include respond-below(custom767) {
                    max-width: 250px;
                    .title {
                      font-size: $font-size-14;
                    }
                    .desc {
                      font-size: $font-size-10;
                    }
                  }
                  @include respond-below(custom575) {
                    max-width: 200px;
                    .title {
                      font-size: $font-size-12;
                    }
                    .desc {
                      font-size: $font-size-9;
                    }
                  }
                }
            }
            .selection-content-box.ads-selections {
                min-width: 350px;
                @include respond-below(custom767) {
                    min-width: 350px;
                }
                @include respond-below(custom575) {
                    min-width: 350px;
                }
            }
            .selection-content-box:hover, .selection-content-box:active {
                opacity: 0.8;
                cursor: pointer;
            }
            .selection-content-box.load-more.disabled {
                // background: $primary-400;
                color: $offwhite;
                cursor: pointer;
            }
            .ad-load-more {
                max-width: 360px;
                border: 1px solid #eaeaea;
                border-radius: 8px;
                margin: 10px;
                padding: 10px;
                background-color: #fff;
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
                color: #4E527C;
              }

            //fb ads container :start
            .ad-post-container {
              width: 360px;
            }
            
            
            .ad-image img {
              height: 200px;
            }
            .ad-body {
              -webkit-box-orient: vertical;
            }
            .line2 {
                height: 150px;
                // -webkit-line-clamp: 2;
            }
            .line4 {
                height: 250px;
                // -webkit-line-clamp: 10;
            }
            //fb ads container :end
        }
    }
}
.recharts-legend-wrapper{
    width:100% !important;
}
.title {
    padding-top: 0.3125rem;
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1.5; 
}

.la-camera, .la-microphone, .la-paperclip {
    font-size: 30px;
    padding: 0px 5px;
    color: $offwhite;
}
.chat-form-control {
    padding: 20px;
    font-size: 16px;
    border: 2px solid transparent;
    border-radius: 50px;
    color: $offwhite;
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    // background-image: linear-gradient($new-grey-bg, $new-grey-bg), $form-border-gradient;
    // background-origin: border-box;
    // background-clip: padding-box, border-box;
    background-color: $color-lightgray;
    margin: 0px 10px 0px 5px;
}
.chat-form-control:focus {
    color: $color-font-gray !important;//$offwhite;
}
.chat-form-control::placeholder {
    color: #cccccc;
}

.chat-text > p{
    margin-bottom: 0;
}


.image-container {
    position: relative;
}

.image-container img {
    display: block;
    max-width: 100%;
    height: auto;
}

.download {
    position: absolute;
    top: 90%; /* Center vertically */
    left: 90%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust for perfect centering */
    color: white;
    font-size: 30px; /* Adjust font size as needed */
    display: flex;
    align-items: center;
    z-index: 10; /* Ensure it's above the image */
}

.download i {
    margin-right: 19px; /* Space between icon and text */
}

.collapsed .chat-window {
    display: none; /* Hide content when collapsed */
}

.collapsed ul {
    display: none; /* Hide tabs when collapsed */
}

.chat-sidebar {
    height: 100%; /* Ensure it takes the full height of the container */
    // border: 1px solid #ddd;
    .nav-tabs {
        margin: opx !important;
        background: transparent;
        a.active {
            background-color: transparent !important;
            border: 0px !important;
            border-bottom: 2px solid $purple !important;
        }
        .nav-link:hover {
            background-color: transparent !important;
            border: 0px !important;
            border-bottom: 2px solid $selection-purple !important;
        }
    }
}

.chat-sidebar-collapse {
    display: "flex";
}

.chat-sidebar-collection-tab{
    padding-bottom: 20px;
}
.chat-sidebar-collections{
    padding:10px 20px;
    .chat-sidebar-collection-title{
        font-size: 16px;
        font-weight: regular;
        margin-bottom: 10px;
        color: $white;
    }
    .chat-sidebar-collection-image {
        width: 100%;
        margin-right: 10px;
        border-radius: 0px;
    }
    .chat-sidebar-collection-copy {
        margin-top: 5px;
        color: $black;
        font-size: 12px;
        background-color: $white;
        border-radius: 5px;
        padding: 10px;
        cursor:pointer;
        text-align: justify;
        max-height: 200px;
        overflow: scroll;
    }
    .brandboost-box {
        max-height: 300px;
        overflow: scroll;
    }
}

.brandboost-container {
    background-image: transparent;//linear-gradient(to right, #7585F1, #DB25DF, #7151DF);
    border-radius: 5px;
    // padding: 8px;
    // margin: 8px;

    .brandboost-box {
        border-radius: 5px;
        max-width: 500px;
        padding: 25px;
        background-color: #fff;
        margin: 3px;
        .keyword-img{
            max-width: 100%;
            height: auto;
            margin-top: 1rem;
        }
        p, span {
            color: #333;
        }  
        .summary {
            line-height: 1.2;
        }
        li {
            margin-bottom: 0;
        }
        .underline{
            text-decoration: underline;
        }
        .text-bold{
            font-weight: $font-weight-semibold;
        }
    }
}
//fb ads container :start
.ad-post-container {
    // width: 360px;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    margin: 10px;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    .ad-header {
        display: flex;
        flex-direction: column; /* Stack profile and title vertically */
        margin-bottom: 10px;
    }
    
    .ad-profile {
        display: flex;
        align-items: center; /* Center vertically */
    }
    
    .profile-picture {
        width: 40px; /* Adjust size as needed */
        height: 40px; /* Adjust size as needed */
        border-radius: 50%; /* Make it circular */
        margin-right: 10px; /* Space between image and page name */
    }
    
    .ad-page-name {
        font-weight: bold;
        color: #4267B2; /* Facebook blue */
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .ad-image img {
        width: 100%;
        // height: 200px;
        border-radius: 8px;
        object-fit: cover;
    }
    
    .placeholder-image {
        width: 100%;
        height: 200px; /* Adjust height as needed */
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px dashed #eaeaea; /* Visual indicator for no image */
        // border-radius: 8px;
        color: #999; /* Color for placeholder text */
    }
    
    .ad-body {
        color: #4E527C !important;
        margin: 10px 0;
        //   max-height: 150px;
        text-align: left;
        //   display: -webkit-box;
        //   -webkit-line-clamp: 2;
        //  -webkit-box-orient: vertical;
        overflow: scroll;
        white-space: normal;
        /* text-overflow: ellipsis; */
    }
    .line2 {
        // height: 150px;
        // -webkit-line-clamp: 2;
    }
    .line4 {
        // height: 250px;
        // -webkit-line-clamp: 10;
    }

    .ad-title {
        height: 2.5em;
        color: #4E527C !important;
        text-align: left;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        background-color: #f0f0f0;
        padding: 10px;
        // font-size: 1rem;
    }

    .ad-title p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .placeholder-title {
        color: #999; /* Color for placeholder text */
        font-style: italic;
    }
    
    .ad-display {
        display: flex;
        align-items: right;
        justify-content: end;
        margin-top: 10px;
    }

    .ad-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;
        padding: 10px;
        border-top: 1px solid grey;
    }
    
    .like-button {
        background-color: #4267B2;
        color: white;
        border: none;
        border-radius: 4px;
        padding: 5px 10px;
        cursor: pointer;
        transition: background-color 0.3s;
    }
    
    .like-button:hover {
        background-color: #365899;
    }
    
    .like-count,
    .comment-count,
    .share-count {
        font-size: 14px;
        color: #606770;
        margin-left: 5px;
    }    
    
    .ad-actions svg {
        margin-right: 8px; /* Space between icon and text */
    }
}
//fb ads container :end

.chat-sidebar-preview-tab {
    .ad-post-container {
        width: 270px;
    }

    .line2 {
        max-height: 200px;
        // -webkit-line-clamp: 2;
    }
}

.creative-copy-wrap {
    display: flex; /* Use flexbox for layout */
    .hidden-checkbox {
        display: none; /* Hides the checkbox visually */
    }
      
    input[type="checkbox"] {
        margin: 0; /* Remove any default margin from checkbox */
    }
    .chat-sidebar-collection-copy {
        align-items: center; /* Align items vertically */
        gap: 10px; /* Add space between checkbox and text */
        margin: 10px 0;
        background: $color-lightgray;
        padding:10px 20px;
        border-radius: 15px;
        max-height: 150px; /* Adjust the height as needed */
        overflow-y: auto; /* Enable vertical scrolling */
        overflow-x: hidden; /* Prevent horizontal scrolling if not needed */
    }
    .chat-sidebar-collection-copy:hover {
        border-color: #ccc; /* Add a hover effect for better UX */
    }
    .chat-sidebar-collection-copy.selected {
        border-color: #007bff; /* Highlight selected state */
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Optional */
        background: $color-lightergray;
    }
    .chat-sidebar-collection-image {
        align-items: center; /* Align items vertically */
        gap: 10px; /* Add space between checkbox and text */
        margin: 10px 0;
        background: $color-lightgray;
        padding:15px;
        border-radius: 15px;
    }
    .chat-sidebar-collection-image:hover {
        border-color: #ccc; /* Add a hover effect for better UX */
    }
      
    .chat-sidebar-collection-image.selected {
        border-color: #007bff; /* Highlight selected state */
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Optional */
        background: $color-lightergray;
    }
}

.creative-selection-modal {
    .creative-selection-body {
        max-height: 400px;
        margin: 0 10px;
        overflow-y: auto;
    }
}



