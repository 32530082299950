//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : _markdown.scss
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (02 Aug 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240802     Jecyline     create _markdown.scss
//* 20240805     Jecyline     refine chat markdown styles
//
//***************************************************************************/
@import "./variables";

/* Heading Styles */
.heading-1 {
  font-size: 2em; /* Font size for h1 */
  color: #1a73e8; /* Text color for h1 */
}

.heading-2 {
  font-size: 1.5em; /* Font size for h2 */
  color: #1a73e8; /* Text color for h2 */
}

.heading-3 {
  font-size: 1.2em; /* Font size for h3 */
  color: #1a73e8; /* Text color for h3 */
}

/* Code Block Styles */
.code-block {
  background-color: #f5f5f5;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  font-size: 0.9em; /* Font size for code blocks */
  color: $violet; /* Text color for code blocks */
  overflow-x: auto; /* Scrollbar for long code lines */
  overflow-y: auto; /* Vertical scrollbar for long code blocks */
  max-height: 400px; /* Fixed height for code blocks */
}

/* Inline Code Styles */
.code-inline {
  background-color: #f5f5f5;
  padding: 2px 4px;
  border-radius: 3px;
  font-size: 0.9em; /* Font size for inline code */
  font-weight: bold; /* Make text bold */
  color: $violet; /* Different color for inline code */
}

/* Code Block Code Styles */
.code-block code{
  font-weight: unset; /* Make text bold */
  color: $primary; /* Text color for code blocks */
}

/* Paragraph and Text Styles */
p {
  font-size: 1em; /* Font size for paragraphs */
  // color: #333; /* Text color for paragraphs */
}

/* Lists */
ul, ol {
  margin-left: 1em;
}

ul li, ol li {
  margin-bottom: 0.5em;
}

/* Links */
// a {
//   color: #1a73e8;
//   text-decoration: none;
// }

// a:hover {
//   text-decoration: underline;
// }

/* Blockquotes */
blockquote {
  color: #d73a49;
  font-style: italic;
}
